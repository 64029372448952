body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.terminal-window {
  max-width: 100%;
  border: 1px solid #333;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.terminal-header {
  display: flex;
  justify-content: space-between;
  background-color: #2d2d2d;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.terminal-content {
  background-color: #000;
  color: #a3c9a8; /* Pastel tamed mint */
  padding: 10px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  white-space: pre-wrap;
  min-height: 200px; /* Adjust the height as needed */
}

.rounded-lg {
  border-radius: 0px; /* Removes rounded corners */
}

.square-corners {
  border-radius: 0px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;